<template>
  <v-row class="d-flex flex-column">
    <v-col  cols="12">
      <!-- <v-row class="d-flex justify-center">
        <v-col id="content-wrapper"
        cols="8"      
        >
          <h1 @click="$router.push('/start'),changePage('start')">Sql</h1>
        </v-col>
      </v-row> -->
      <v-row class="d-flex justify-center bg-dblue">
        <v-col cols="8" class="py-16 px-0">
          <h1 class="text-white text-h4 mb-15">NitrosBase</h1>
          <p v-if="lang == 'ru'" class="text-white text-h5 mb-10">Высокопроизводительная реляционная СУБД для современных IT решений:</p>
          <p v-if="lang == 'en'" class="text-white text-h5 mb-10">High-performance relational database management system for modern IT solutions:</p>
          <v-row class="d-flex">
            <v-col cols="6">
              <v-list
                class="bg-dblue"
              >
                <v-list-item                   
                  v-for="n in listHead" 
                  :key="n"
                  class="text-white pl-0"
                >
                  <div class="d-flex justify-start">                    
                    <p class="backV">{{ n }}</p>
                  </div>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="6" class="d-flex align-center">
              <v-img src="../../assets/sqlsection1.svg"></v-img>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-if="lang == 'ru'" class="d-flex justify-center bg-lgrey">
        <v-col cols="8" class="py-16 px-0">
          <p style="color: #444455; font-weight: 300;">
            СУБД NitrosBase включена в Реестр Российского ПО. Реестровая запись №12426 от 30.12.2021 Произведена на основании поручения Министерства цифрового развития, связи и массовых коммуникаций Российской Федерации от 30.12.2021 по протоколу заседания экспертного совета от 27.12.2021 №1735пр
          </p>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col cols="8" class="py-16 px-0">
          <h1 v-if="lang == 'ru'" class="text-red text-h4 mb-13 font-weight-thin">Диалект SQL, совместимый с Microsoft Transact-SQL</h1>
          <h1 v-if="lang == 'en'" class="text-red text-h4 mb-13 font-weight-thin">Microsoft Transact-SQL Compliant SQL Dialect</h1>
          <v-list>
            <v-list-item 
              v-for="n in listDialect" 
              :key="n"
              class="pl-0"
            >
              <p class="backDot" style="color: #444455; font-weight: 300;">
                {{ n }}
              </p>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center bg-lgrey">
        <v-col cols="8" class="py-16 px-0">
          <h1 v-if="lang == 'ru'" class="text-red text-h4 mb-13 font-weight-thin">Быстродействие</h1>
          <h1 v-if="lang == 'en'" class="text-red text-h4 mb-13 font-weight-thin">Performance</h1>
          <v-row class="line">
            <v-col cols="6" class="py-0 px-0">
              <v-list class="bg-lgrey py-0 ">
                <v-list-item 
                  v-for="n in listPerformanceR" 
                  :key="n"
                >
                  <p class="backDot" style="color: #444455; font-weight: 300;">
                    {{ n }}
                  </p>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="6" class="py-0 px-0">
              <v-list class="bg-lgrey py-0 px-0">
                <v-list-item 
                  v-for="n in listPerformanceL" 
                  :key="n"
                >
                  <p class="backDot" style="color: #444455; font-weight: 300;">
                    {{ n }}
                  </p>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
          <v-row class="pt-16">
            <v-col cols="8" class="pa-0" align-self="end">
              <v-list class="d-flex justify-space-between pa-0 bg-lgrey">
                <v-col cols="2" class="pa-0">
                  <v-list-item>
                    <v-img src="../../assets/sql/diagram1.svg"></v-img>
                    <!-- <img src="../../assets/sql/diagram1.svg" alt="pic"> -->
                  </v-list-item>
                </v-col>
                <v-col cols="2" class="pa-0">
                  <v-list-item>
                    <v-img src="../../assets/sql/diagram2.svg"></v-img>
                    <!-- <img src="../../assets/sql/diagram1.svg" alt="pic"> -->
                  </v-list-item>
                </v-col>
                <v-col cols="2" class="pa-0">
                  <v-list-item>
                    <v-img src="../../assets/sql/diagram3.svg"></v-img>
                    <!-- <img src="../../assets/sql/diagram1.svg" alt="pic"> -->
                  </v-list-item>
                </v-col>
                <v-col cols="2" class="pa-0">
                  <v-list-item>
                    <v-img src="../../assets/sql/diagram4.svg"></v-img>
                    <!-- <img src="../../assets/sql/diagram1.svg" alt="pic"> -->
                  </v-list-item>
                </v-col>
                <v-col cols="2" class="pa-0">
                  <v-list-item>
                    <v-img src="../../assets/sql/diagram5.svg"></v-img>
                    <!-- <img src="../../assets/sql/diagram1.svg" alt="pic"> -->
                  </v-list-item>
                </v-col>
              </v-list>
            </v-col>
            <v-col cols="4">
              <v-row class="bg-dblue ml-4">
                <v-col cols="12">
                  <p v-if="lang == 'ru'" class="text-white">Сравнение выполнялось на запросах и данных одного из клиентов</p>
                  <p v-if="lang == 'en'" class="text-white">The comparison was performed on client's data and queries</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-list class="bg-lgrey">
                    <v-list-item density="compact" height="30">
                      <div class="d-flex align-center">
                        <div class="color red "> </div><span>NitrosBase</span>
                      </div>
                    </v-list-item>
                    <v-list-item density="compact" height="30">
                      <div class="d-flex align-center">
                        <div class="color blue"></div><span>Postgre SQL</span>
                      </div>
                    </v-list-item>
                    <v-list-item density="compact" height="30">
                      <div class="d-flex align-center">
                        <div class="color gray"> </div><span>MS SQL</span>
                      </div>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col cols="8" class="py-16 px-0">
          <h1 v-if="lang == 'ru'"  class="text-red text-h4 mb-13 font-weight-thin">Транзакции</h1>
          <h1 v-if="lang == 'en'" class="text-red text-h4 mb-13 font-weight-thin">Transactions</h1>
          <v-col class="d-flex justify-space-between">
            <v-col cols="2" class="d-flex align-center flex-column">
              <v-img               
                src="../../assets/sql/icon1.svg"
                height="82"
                width="82"
              >
              </v-img>
              <p class="text-center text-subtitle-2 mt-5" style="color: #444455; font-weight: 300;">Read Committed, Repeatable Read, Snapshot, Serializable</p>
            </v-col>
            <v-col cols="2" class="d-flex align-center flex-column">
              <v-img               
                src="../../assets/sql/icon2.svg"
                height="82"
                width="82"
              >
              </v-img>
              <p class="text-center text-subtitle-2 mt-5" style="color: #444455; font-weight: 300;">Multi-Version concurrency Control (MVCC)</p>
            </v-col>
            <v-col cols="2" class="d-flex align-center flex-column">
              <v-img               
                src="../../assets/sql/icon4.svg"
                height="82"
                width="82"
              >
              </v-img>
              <p v-if="lang == 'ru'" class="text-center text-subtitle-2 mt-5" style="color: #444455; font-weight: 300;">Быстрое обнаружение deadlock и управление откатами</p>
              <p v-if="lang == 'en'" class="text-center text-subtitle-2 mt-5" style="color: #444455; font-weight: 300;">Fast deadlock detection and rollback management</p>
            </v-col>
            <v-col cols="2" class="d-flex align-center flex-column">
              <v-img               
                src="../../assets/sql/icon5.svg"
                height="82"
                width="82"
              >
              </v-img>
              <p v-if="lang == 'en'" class="text-center text-subtitle-2 mt-5" style="color: #444455; font-weight: 300;">Record level locking, timeout management</p>
              <p v-if="lang == 'ru'" class="text-center text-subtitle-2 mt-5" style="color: #444455; font-weight: 300;">Локирование на уровне записи, управление временем ожидания</p>
            </v-col>
            <v-col cols="2" class="d-flex align-center flex-column">
              <v-img               
                src="../../assets/sql/icon6.svg"
                height="82"
                width="82"
              >
              </v-img>
              <p v-if="lang == 'en'" class="text-center text-subtitle-2 mt-5" style="color: #444455; font-weight: 300;">Fast commit, rollback, garbage collection, logging, etc.</p>
              <p v-if="lang == 'ru'" class="text-center text-subtitle-2 mt-5" style="color: #444455; font-weight: 300;">Быстрые синхронизация, commit, rollback, сборка мусора, журналирование</p>
            </v-col>            
          </v-col>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center bg-lgrey reliability">
        <v-col cols="8" class="py-16 px-0 d-flex justify-end">
          <v-col cols="6" class="pa-0">
            <h1 v-if="lang == 'en'" class="text-red text-h4 font-weight-thin">Reliability</h1>
            <h1 v-if="lang == 'ru'" class="text-red text-h4 font-weight-thin">Надежность</h1>
            <v-col class="d-flex flex-column mt-10 pa-0">
              <p v-for="n in reliabilityList" :key="n" class="backDot font-weight-thin" style="color: #444455;">{{n}}</p>
            </v-col>
          </v-col>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col cols="8" class="py-16 px-0 d-flex justify-start scale">
          <v-col cols="6" class="pa-0">
            <h1 v-if="lang == 'ru'" class="text-red text-h4 font-weight-thin">Масштабируемость</h1>
            <h1 v-if="lang == 'en'" class="text-red text-h4 font-weight-thin">Scalability</h1>
            <v-col class="d-flex flex-column mt-10 pa-0">
              <p v-for="n in listScale" :key="n" class="backDot font-weight-thin" style="color: #444455;">{{n}}</p>
            </v-col>
          </v-col>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center bg-lgrey unicode">
        <v-col cols="8" class="py-16 px-0 d-flex justify-end">
          <v-col cols="6" class="pa-0">
            <h1 v-if="lang == 'ru'" class="text-red text-h4 font-weight-thin">Поддержка UNICODE</h1>
            <h1 v-if="lang == 'en'" class="text-red text-h4 font-weight-thin">UNICODE support</h1>
            <v-col class="d-flex flex-column mt-10 pa-0">
              <p v-for="n in listUnicode" :key="n" class="backDot font-weight-thin" style="color: #444455;">{{n}}</p>
            </v-col>
          </v-col>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col cols="8" class="py-16 px-0 d-flex justify-start extension">
          <v-col cols="6" class="pa-0">
            <h1 v-if="lang == 'ru'" class="text-red text-h4 font-weight-thin">Расширения SQL</h1>
            <h1 v-if="lang == 'en'" class="text-red text-h4 font-weight-thin">SQL extensions</h1>
            <v-col class="d-flex flex-column mt-10 pa-0">
              <p v-for="n in listExtension" :key="n" class="backDot font-weight-thin" style="color: #444455;">{{n}}</p>
            </v-col>
          </v-col>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center bg-lgrey">
        <v-col cols="8" class="py-16 px-0">
          <formContact />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import formContact from "../../components/formContact/formContact.vue";
import { mapMutations, mapState } from "vuex";
import "./sql.scss";

export default {
  components: { formContact },
  name: 'sql',
   computed: {
    ...mapState(['lang','page']),
    listHead() {
      return this.lang == 'en' ? this.listNamesEn : this.listNames
    },
    listDialect() {
      return this.lang == 'en' ? this.listNames2En : this.listNames2
    },
    listPerformanceR() {
      return this.lang == 'en' ? this.listNames3En : this.listNames3
    },
    listPerformanceL() {
      return this.lang == 'en' ? this.listNames4En : this.listNames4
    },
    listReliability() {
      return this.lang == 'en' ? this.reliabilityListEn : this.reliabilityList
    },
    listScale() {
      return this.lang == 'en' ? this.scaleListEn : this.scaleList
    },
    listUnicode() {
      return this.lang == 'en' ? this.unicodeListEn : this.unicodeList
    },
    listExtension() {
      return this.lang == 'en' ? this.extensionListEn : this.extensionList
    },
  },
  data() {
    return {
      listNames: [
        'высокая производительность',
        'надежность',
        'распределенная обработка',
        'поддержка транзакций',
        'масштабируемость'
      ],
      listNamesEn: [
        'high performance',
        'reliability',
        'distributed data processing',
        'transaction support',
        'scalability',
      ],
      listNames2: [
        'Все типы: DECIMAL, NVARCHAR, BINARY, ROWVERSION и т.д.',
        'Полный набор DML операций: SELECT, INSERT, UPDATE, DELETE, INSERT… SELECT, SELECT INTO, вложенные запросы, и т.д.',
        'Полный набор DDL операций, включая создание, модификацию и удаление постоянных и временных таблиц, индексов, constraints, и т.д.',
        'Полный набор Flow Control операций, включая: IF...ELSE, WHILE, TRY...CATCH, и т.д.',
        'Управление переменными, включая DECLARE, SET, и т.д.',
        'JOIN-операции - все виды: LEFT, RIGHT, INNER, и т.д.',
        'Все необходимые функции и операторы: CASE, IN, EXISTS, CAST, и т.д.',
        'Транзакции - все уровни изоляции.',
        'Хранимые процедуры, функции и триггеры.'
      ],
      listNames2En: [
        'Basic types: DECIMAL, NVARCHAR, BINARY, ROWVERSION, etc.',
        'Complete set of DML operations: SELECT, INSERT, UPDATE, DELETE, INSERT … SELECT, nested queries, etc.',
        'DDL operations, including creation, modification and deletion of permanent and temporary tables, indexes, constraints, etc.',
        'Complete set of Flow Control operations including IF# ... ELSE, WHILE, TRY ... CATCH, etc.',
        'Variable management, including DECLARE, SET, etc.',
        'JOIN operations LEFT, RIGHT, etc.',
        'Functions and operators including CASE, IN, EXISTS, CAST, etc.',
        'Transactions - all isolation levels.',
        'Stored procedures, functions and triggers.'
      ],
      listNames3: [
        'Ускорение обработки запросов',
        'Использование разделяемой памяти для ускорения взаимодействия клиент-сервер',
        'Режим bulk insert - до 2 млн QPS при параллельной обработке - 100 000+ операций update/insert/delete в секунду',        
      ],
      listNames3En: [
        'Speeding up query processing',
        'Speeding up the client-server communication via shared memory utilization',
        'Bulk insert mode - parallel processing up to 2 mln QPS 100 000+ update/insert/deletes per second',        
      ],
      listNames4: [
        'Оптимизация запросов и управление планом выполнения',
        'Быстрая работа с временными таблицами',
        'Быстрая обработка транзакций',
        'Специальные индексы, значительно ускоряющие обработку JOIN запросов',
      ],
      listNames4En: [
        'Query optimization and the execution plan control',
        'Very fast creation and deletion of temporary tables',
        'Speeding up the transaction processing',
        'Special indexes to significantly speed up the processing of JOIN queries',
      ],
      reliabilityList: [
        'Write-ahead logging, Backup/Restore, Incremental backup',
        'Синхронные и асинхронные репликации',
        'Active standby to NitrosBase instances, Active standby to MS SQL instances',
      ],
      reliabilityListEn: [
        'Write-ahead logging, Backup/Restore, Incremental backup',
        'Synchronous and asynchronous replication',
        'Active standby to NitrosBase instances, Active standby to MS SQL instances',
      ],
      scaleListEn: [
        'Parallel operation on clusters up to 1000 nodes',
        'Sharding and replication support',
        'Almost linear performance growth with the number of nodes increase',
        'Distributed JOIN and Graph Query Processing',
        'Fault Tolerance, High Availability 99.99%',
        'Parallel synchronous functioning with MS SQL'
      ],
      scaleList: [
        'Параллельная работа на кластерах от 2 до 1000 узлов',
        'Поддержка шардинга и репликации',
        'Почти линейный рост производительности c ростом количества узлов',
        'Распределенная обработка JOIN и графовых запросов',
        'Fault Tolerance, High Availability 99.99%',
        'Возможность параллельной синхронной работы с MS SQL'
      ],
      unicodeList: [
        'Case-insensitive and accent-insensitive collations',
        'Поддержка ICU collations',
        'Быстрая обработка general collation на уровне Case-insensitive and accent-insensitive collations',
      ],
      unicodeListEn: [
        'Case-insensitive and accent-insensitive collations',
        'ICU collation support',
        'Fast general collation processing at the level of case- and accent-insensitive collations',
      ],
      extensionList: [
        'Специальные индексы для ускорения обработки JOIN запросов и организации графовых связей',
        'Graph-SQL - расширения SQL для упрощение сложных JOIN запросов и выполнения более сложных графовых запросов',
        'Возможность описания способа и плана выполнения запроса, включая выполнение промежуточных операций',
        'Выполнение рекурсивных операций'
      ],
      extensionListEn: [
        'Special index for speeding up the JOIN query processing and graph link navigation',
        'Graph-SQL - an SQL extension to simplify complex JOIN queries and express the graph queries',
        'Describing the query execution plan, including intermediate operations',
        'Recursive operations'
      ],
    }
  },
  methods: {
    ...mapMutations(['SET_PAGE']),
    changePage(page) {
      this.$store.commit('SET_PAGE',page)
    }
  }
}
</script>

<style>

</style>